<template>
  <div id="js-loader" class="loader">
    <div class="loader-animation"></div>
  </div>
  <div class="maincontent" id="content" style="min-height: 605px">
    <div
      class="maincontentinner"
      style="opacity: 1; left: 0px; transition: all 0.5s ease 0s"
    >
      <div class="mt_wrap">
        <h1 class="ttl_1">{{this.adminpagetype.title_jp}}<span></span></h1>
        <nav class="breadcrumb_wrap">
          <ul class="breadcrumb">
            <li>
              <router-link :to="{name: 'Dashboard'}"> ダッシュボード </router-link>
            </li>
            <li>
              <router-link :to="{ name: 'Control message', params: { type: (message_type) }}">{{this.adminpagetype.title_jp}}</router-link>
            </li>
            <li><span>詳細</span></li>
          </ul>
        </nav>
      </div>
      <!-- <SuccessMessage v-if="successMessage" /> -->

      <div class="toggle_wrap">
        <div class="toggle_dsn">
          <span class="modeon">表示</span>

          <router-link :to="{name: 'Page Edit',params: { type:message_type },}">編集</router-link>
        </div>
        <!--toggle_dsn-->
      </div>

      <div class="command_wrap command_bottom">
        <!-- <div class="leftarea btn_wrap">
          <div class="return_link">
            <router-link :to="{ name: 'Control message', params: { type: (message_type) }}">
              <span class="icon"><i class="fas fa-chevron-left"></i></span
              >{{this.adminpagetype.title_jp}}一覧
            </router-link>
          </div>
          <div></div>
        </div> -->
        <!--leftarea-->
        <div class="rightarea">
          <!--commandtext_wrap-->
        </div>
        <!--rightarea-->
      </div>
      <!--command_wrap-->
      <!-- <Spinner v-if="spinner" /> -->

      <div class="contentout_wrap">
        <div class="content_wrap twocolumn">
          <div class="box_wrap left">
            <h2 class="ttl_2">{{this.adminpagetype.title_jp}}詳細</h2>
            <div class="scr_outwrap">
              <div class="scr_wrap">
                <table class="formtable">
                  <tbody>
                    <tr>
                      <th class="wid_200px">タイトル</th>
                      <td>
                        <div class="form_outwrap">
                          <div class="wrap wid_100per m_b0 p_r0">
                            <div class="in_wrap">
                              <p class="f_500">{{ showmessage.title }}</p>
                            </div>
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th class="wid_200px">本文</th>
                      <td>
                        <div class="form_outwrap">
                          <div class="wrap wid_100per p_r0 m_b0">
                            <div class="in_wrap">
                              <div class="show_body_post default">
                                <p v-html="showmessage.content"></p>
                              </div>
                            </div>
                            <!--in_wrap-->
                          </div>
                          <!--wrap-->
                        </div>
                        <!--form_outwrap-->
                      </td>
                    </tr>
                  </tbody>
                </table>
                <!--formtable-->
              </div>
              <!--scr_wrap-->
            </div>
            <!--scr_outwrap -->
          </div>
          <!--box_wrap-->
          <div class="box_wrap right">
            <h2 class="ttl_2">公開情報</h2>
            <div class="form_outwrap">
              <div class="wrap wid_100per p_r0">
                <div><p class="ttl_3">公開ステータス</p></div>
                <div class="in_wrap">
                  <p>{{ showmessage.status }}</p>
                </div>
              </div>
              <!--wrap-->

              <div class="wrap wid_100per p_r0">
                <div><p class="ttl_3">公開日付</p></div>
                <div class="in_wrap">
                  <p>{{ formatDate_time(showmessage.published_at) }}</p>
                </div>
              </div>
              <!--wrap-->
              <div class="wrap wid_100per p_r0">
                <div><p class="ttl_3">記事登録</p></div>
                <div class="in_wrap">
                  <p v-if="showmessage.from_user">
                    登録日： {{ formatDate_time(showmessage.created_at) }}
                    <br />作成者：{{showmessage.from_user.last_name}}{{showmessage.from_user.first_name}}
                  </p>
                </div>
              </div>
              <!--wrap-->
              <!-- <div class="wrap wid_100per p_r0">
                <div><p class="ttl_3">最終更新</p></div>
                <div class="in_wrap">
                  <p>
                    更新日：{{ formatDate_time(showmessage.updated_at) }}
                    <br />作成者：{{ userFullName }}
                  </p>
                </div>
              </div> -->
              <!--wrap-->
            </div>
            <!--form_outwrap-->
          </div>
          <!--box_wrap-->
        </div>
        <!--content_wrap-->
      </div>
      <!--contentoutwrap-->
    </div>
    <!--maincontentinner-->
  </div>
</template>

<script>
import Api from "@/apis/Api";
import "@/assets/front_component/js/functions.js";
import $ from 'jquery';
import message from "@/apis/Message";
import user from "@/apis/User";
import AdminPageType from "@/const/AdminPageType.json";
import dayjs from 'dayjs';

export default {
  name: "message",
  data() {
    return {
		userlists:"",
      showmessage: {},
      AdminPageType() {
        return AdminPageType
      },
      adminpagetype: {},
      files: {},
	approval_ng:{},
	approval_post_data:{}
    };
  },
  components: {  },
  watch: {
    '$route' (to, from) {
      if(from.name == 'Page Show' && to.name == 'Page Show'){
        $(function(){
          $('#js-loader').show();
          $(window).scrollTop(0);
        });
        this.showmessage = {};
        this.message_type = this.$route.params.type;
        this.adminpagetype = AdminPageType[this.message_type];
        this.GetPageId();
        $(function(){
          $('#js-loader').delay(500).fadeOut(600);
        });
      }
    }
  },
  beforeCreate(){
    $(function(){   
      // body に open クラスをつけたりはずしたりする( open クラスは空)
      $('.g_header_list').removeClass('open');
      $('#button').removeClass('active');
      $(document.body) .removeClass('open');
      var result = $('body').attr('style');
      var int_data = result.replace(/[^0-9]/g, '');
      $("html,body").css({
        "overflow": "auto",
        "height": "auto",
      });
      //bodyfixedを解除する
      $('body').css({
        'position': '',
        'width': '',
        'top': ''
      });
      $(window).scrollTop(int_data); 
      $('.g_header_list  > li.has_child').removeClass('open');
      $('.g_header_list  > li.has_child').find('.menu_outwrap').slideUp();
    });
    $(function(){
      $('#js-loader').show();
      $(window).scrollTop(0);
    });

  },
  created() {
    this.message_type = this.$route.params.type;
    this.adminpagetype = AdminPageType[this.message_type];
    this.GetPageId();
  },
    mounted() {
            document.body.className = 'page_control';
			this.$nextTick(function() {
				$(function(){
					$('.menu_icon').click(function(){
						if($('.menu_list_wrap').hasClass('show')){
							$('.menu_list_wrap').removeClass('show');
						}else{
							$('.menu_list_wrap').addClass('show');
						}
					});
				});
				$(document).click(function(event) {
					if(!$(event.target).closest('.menu_wrap').length) {
						$('.menu_list_wrap').removeClass('show');
					}
				});
				$(function(){
					var content_height = $("#app").height(); // コンテンツの高さを取得
					var pagetop_show = content_height - 1500; // ページトップを出すの高さを設定
					$(window).on("scroll", function () {
						var scroll = $(window).scrollTop();
						var windowHeight = $(window).height();
						//ページトップ表示
						if(scroll > pagetop_show && scroll>60){
							$('.pagetop_wrap').addClass('show');
						} else {
							$('.pagetop_wrap').removeClass('show');
						}
					});
				});
				$(function(){
					$('#js-loader').delay(300).fadeOut(600);
					$(window).scrollTop(0);
				});
        //Jquery
        // youtubeがタグの構成上表示出来ないので以下でタグを変換している
        $(function () {
          $("figure.media").each(function () {
            var $y_url = $(this).children().attr("url").replace("https://youtu.be/", "");
            $y_url = $(this).children().attr("url").replace("https://www.youtube.com/watch?v=", "");
            $(this)
              .children()
              .replaceWith(
                "<iframe class='post_youtube' title='YouTube video player' frameborder='0' allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture' allowfullscreen></iframe>"
              );
              console.log($y_url);
            $(this)
              .children(".post_youtube")
              .attr("src", "https://www.youtube.com/embed/" + $y_url);
          });
        });
			});
        $('figure.media').each(function() {
              var $y_url = $(this).children().attr('url').replace('https://youtu.be/', '');
              $(this).children().replaceWith("<iframe class='post_youtube' title='YouTube video player' frameborder='0' allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture' allowfullscreen></iframe>");
              $(this).children('.post_youtube').attr("src", "https://www.youtube.com/embed/"+$y_url);
        });
        },
  methods: {
    GetPageId() {
      message.list(this.message_type)
        .then((response) => {
          if (response != null) {
            console.log(response);
            this.message_id = response.data.data[0].id;
            this.ShowMessage(this.message_id);
          }
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
        });
    },
	isRequired(value) {
      if (value) {
        return true;
      }
    return '必須です';
    },
    formatDate: dateStr => dayjs(dateStr).format('YYYY.MM.DD'),
    formatDate_time: dateStr => dayjs(dateStr).format('YYYY-MM-DD'),
    formattime: dateStr => dayjs(dateStr).format('YYYY-MM-DD hh:mm:ss'),
    ShowMessage(id) {
      message.show(id)
        .then((response) => {
          if (response != null) {
            this.showmessage = response.data.data;
            console.log(this.showmessage);
            //Jquery
            // youtubeがタグの構成上表示出来ないので以下でタグを変換している
            $(function() {
              $('figure.media').each(function() {
                var $y_url = $(this).children().attr('url').replace('https://youtu.be/', '');
                $(this).children().replaceWith("<iframe class='post_youtube' title='YouTube video player' frameborder='0' allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture' allowfullscreen></iframe>");
                $(this).children('.post_youtube').attr("src", "https://www.youtube.com/embed/"+$y_url);
              });
            });
            if(this.showmessage.from_id != null){
              this.getFromUser(this.showmessage.from_id);
            }
          }
        })
        .catch((error) => {
          console.log(error);
          this.$router.push({ name: 'Front Error Catch' });
        })
        .finally(() => {
        });
    },
    getFromUser(user_id) {
      user.show(user_id)
        .then((response) => {
          if (response != null) {
            this.showmessage.from_user = response.data.data;
          }
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
        });
    },

  },
};
</script>

<style scoped>
/* 外部CSSスタイルシートを追加する */
@import "../../../assets/control_panel/scss/main.scss";
</style>
